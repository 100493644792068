import { css } from '@emotion/core'

export const styleAOlimpiada = css`
  position: relative;
  padding-top: 40px;
  padding-bottom: 80px;
  z-index: 1;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  background-color: #fff;
`
export const container = css`
  max-width: 1013px;
  margin: 0 auto;
`

export const title = css`
  font-size: 14px;
line-height: 16px;
  text-transform: uppercase;
  color: #2A323C;
  padding-bottom: 16px;
  text-align: center;
`
export const content_1 = css`
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #2A323C;
    padding-bottom: 12px;
    span {
      font-weight: 700;
    }
  }
`
export const content_2 = css`
  p {
    font-size: 16px;
    line-height: 24px;
    color: #2A323C;
    padding-bottom: 24px;
  }
  h2 {
    font-weight: bold;
    font-size: 36px;
    color: #2A323C;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    padding: 16px;
  }
`
export const contactForm = css`
  display: flex;
  flex-direction: column;
  width: 800px;
  background: #2A323C;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 24px;
  h2 {
    font-size: 40px;
    color: #fff;
    padding-bottom: 48px;
    text-align: center;
    span {
      font-weight: 700;
    }
  }
  h6 {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 32px;
    padding-bottom: 16px;
  }
  img {
    width: 11px;
    margin: 0 auto;
    padding-bottom: 24px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    max-width: 100%;
    h2 {
      font-size: 26px;
    }
  }
`
export const label = css`
  width: 328px;
  margin-bottom: 24px;
  p{
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 8px;
  }
  input {
    width: 440px;
    height: 40px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    input {
      width: 328px;
      height: 40px;
    }
  }
`
export const checkBox = css`
  p{
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 8px;
  }
`
export const btn = css`
  font-family: 'Montserrat', sans-serif;
  width: 440px;
  height: 50px;
  margin: 0 auto;
  margin-top: 40px;
  background-color: #0071FF;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  line-height: 17px;
  border-color: #0071FF;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
  }
`

export const checkBtn = css`
  font-family: 'Montserrat', sans-serif;
  width: 210px;
  height: 50px;
  border: 1px solid #FFFFFF;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
    background-color: white;
    color: #2A323C;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 150px;
  }
`

export const formInputs = css`
  border: 1px solid #FFFFFF;
  display: block;
  margin: 10px 0;
  position: relative;
  width: 210px;
  label {
    text-transform: uppercase;
    width: 210px;
    height: 50px;
    justify-content: center;
    display: flex;
    text-align: left;
    align-items: center;
    color: white;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: '';
      background-color: #5562eb;
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }
    &:after {
      width: 32px;
      height: 32px;
      content: '';
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }
  input:checked ~ label {
      color: #fff;

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }
      &:after {
        background-color: transparent;
        border-color: transparent;
      }
    }

    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
    @media (min-width: 260px) and (max-width: 1000px) {
      width: 100%;
    }
`
export const checksItems = css`
  display: flex;
  @media (min-width: 260px) and (max-width: 1000px) {
    flex-direction: column;
  }
`
export const formInputsLr = css`
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 260px) and (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`
export const inputsItem = css`
  @media (min-width: 260px) and (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`

export const about = css`
  background: #2A323C;
  display: flex;
  justify-content: center;
  margin-top: 88px;
  flex-direction: column;
  @media (min-width: 260px) and (max-width: 1000px) {
    img {
      width: 330px;
      height: 74px;
    }
  }
`

export const content_about = css`
  p {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  li {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    padding-bottom: 16px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    padding: 16px;
  }
`
export const itemConteudo = css`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    z-index: 99;
  }
`
export const contentApoio = css`
  background-color: #2A323C;
  width: 900px;
  border-radius: 4px;
  margin-left: -70px;
  height: 140px;
  align-items: center;
  justify-content: center;
  display: flex;
  p {
    color: #fff;
    padding-bottom: 0px;
    width: 700px;
  }
`
export const comoFunciona = css`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  p{
    max-width: 280px;
    text-align: center;
  }
`

export const desktop = css`
  @media (min-width: 260px) and (max-width: 1000px) {
    display: none;
  }
`
export const mobile = css`
  @media (min-width: 1001px) {
    display: none;
  }
`
export const contentApoioMobile = css`
  background: #2A323C;
  border-radius: 4px;
  margin-top: -110px;
  p {
    color: #fff !important;
    padding: 24px;
    padding-top: 114px;
  }
`
export const videoFaq = css`
  h2 {
    font-weight: bold;
    font-size: 24px;
    color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    text-align: center;
  }

`