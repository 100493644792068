import React from 'react'
import ArrowDown from '../images/arrow-down.png'
import Arrow from '../images/polygon2.png'

import {
  styleAOlimpiada,
  container,
  title,
  content_2,
  contactForm,
} from '../components/styles/InternalPages.styles'

const AOlimpiada = props => (
  <div css={styleAOlimpiada}>
    <div css={container}>
      <div css={title}>
        SOBRE O VEREDAS<br />
        <img src={Arrow} alt='' />
      </div>
      <div css={content_2}>
        <h2>Influencie, inspire e engaje </h2>
        <p>Uma boa narrativa é um dos meios mais poderosos para influenciar, ensinar e inspirar. Ela cria conexões entre as pessoas, e entre as pessoas e as ideias. Constrói familiaridade e confiança e é muito mais excitante do que uma lista fria de dados ou uma discussão abstrata de ideias. Uma história convincente nos envolve e nos emociona.</p>
        <p>Uma boa história não é apenas uma ferramenta a ser usada de vez em quando. É uma estratégia essencial para o posicionamento, a comunicação e o marketing da escola. Se você deseja que a sua marca conquiste a confiança, o entusiasmo e a lealdade da comunidade escolar, e gere valor para o colégio, uma boa narrativa é a resposta. Afinal, uma boa história não é somente lembrada, ela é compartilhada!</p>
      </div>
      <div css={contactForm}>
        <h6>TENHO INTERESSE</h6>
        <img src={ArrowDown} alt='' />
        <h2>Quero conhecer o projeto <span>#escolasparaofuturo</span></h2>
      </div>
    </div>
  </div>
)

export default AOlimpiada
